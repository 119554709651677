let theBody = document.body,
    themeSwitcher = document.getElementById('themeSwitcher'),
    siteStyle = document.getElementById('style'),
    stylePath = 'dist/css/styles-color.min.css',
    currColor = localStorage.getItem('mashley-theme');

themeSwitcher.addEventListener('click', setTheme);

// no current color, set to dark
if ( currColor === null ) {
    localStorage.setItem('mashley-theme', 'dark');
}

/**
 * setTheme - set between light or dark
 */
function setTheme() {
    let themeColor = localStorage.getItem('mashley-theme');

    if ( themeColor === 'light' ) {
        localStorage.setItem('mashley-theme', 'dark');
        themeColor = 'dark';
    } else {
        localStorage.setItem('mashley-theme', 'light');
        themeColor = 'light';
    }

    getStyles( themeColor );

    getBtnClass( themeColor );
}

/**
 * getStyles - make sure the correct theme styles are set
 */
function getStyles( color ){
    let newStyle = stylePath.replace('color', color);
    
    if ( color !== null ) {
        siteStyle.setAttribute('href', newStyle);
    }
}

/**
 * getBtnClass - make sure the button has the correct class
 */
function getBtnClass( color ){
    if ( color == 'dark' || color === null ) {
        themeSwitcher.classList.remove('light');
    } else {
        themeSwitcher.classList.add('light');
    }

    // dark = on, sun
    // light = off, moon && .light
}

/**
 * init
 */
function init( color ) {
    getStyles( color );
    getBtnClass( color );
}

document.addEventListener("DOMContentLoaded", function(){
    init( currColor );
    theBody.classList.add('visible');
});